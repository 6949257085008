import React, { FunctionComponent } from "react";
import { graphql, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/layout";
import Container from "../components/container";
import SEO from "../components/seo";
import Hero from "../components/hero";
import { extractHtml, extractImage } from "../util";
import PageText from "../components/page-text";
import PageTitle from "../components/page-title";
import SessionDuration from "../components/session-duration";

const query = graphql`
    query {
        text: markdownRemark(fileAbsolutePath: {regex: "/remedial-teaching.md/"}) {
            html
        }
    }
`;

const RemedialTeaching: FunctionComponent<PageProps> = ({ location }) => {
    const data = useStaticQuery(query);
    const { text } = extractHtml(data);

    return (
        <Layout currentPath={location.pathname}>
            <SEO title="Remedial teaching" description="Hoe kan remedial teaching uw kind helpen? Daar kan Praktijk de Leersprong antwoord op geven." />
            <Hero />

            <PageTitle>Remedial teaching</PageTitle>
            <SessionDuration duration="45 minuten" />

            <Container>
                <PageText text={text} />
            </Container>
        </Layout>
    );
};

export default RemedialTeaching;